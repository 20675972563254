export const RallyActions = {
    GET_RALLIES: "GET_RALLIES",
    GET_RALLY_BY_SLUG: "GET_RALLY_BY_SLUG",
    REGISTER_FOR_RALLY: "REGISTER_FOR_RALLY",
    SET_REGISTER_SESSION: "SET_REGISTER_SESSION",
    CLEAR_REGISTER_SESSION: "CLEAR_REGISTER_SESSION",
};

export function getRallies() {
    return {
        type: RallyActions.GET_RALLIES,
    };
}

export function getRallyBySlug(slug) {
    return {
        type: RallyActions.GET_RALLY_BY_SLUG,
        payload: slug,
    };
}

export function registerForRally(payload) {
    return {
        type: RallyActions.REGISTER_FOR_RALLY,
        payload,
    };
}

export function setRegisterSession(payload) {
    return {
        type: RallyActions.SET_REGISTER_SESSION,
        payload,
    }
}

export function clearRegisterSession() {
    return {
        type: RallyActions.CLEAR_REGISTER_SESSION,
    }
}