import { takeEvery, call, put, takeLatest } from "redux-saga/effects";

import { authenticationSliceActions } from "../../slices";

import { AuthenticationActions } from "../actions";


import Axios from "axios";

export function* loginAccountSaga(account) {

    try {
        const { data } = yield call(async () => {
            return await Axios.post(
                '/api/auth/login',
                 {...account.payload },
            );
        });
        localStorage.setItem("token", data);
        yield put(authenticationSliceActions.setToken(data));
        const response = yield call(async () => {
            return await Axios.get(
                `/api/auth/validate/${data}`,
            );
        });
        yield put(authenticationSliceActions.setUser(response.data));
        yield put(authenticationSliceActions.setLoginSuccess(true));
    } catch (err) {
        console.error("Could not login an account because of an error: ", err);
        yield put(authenticationSliceActions.changeAuthenticationFailed(true));
    }
}

export function* validateTokenSaga(token) {
    try {
        if (token.payload) {
            const response = yield call(async () => {
                return await Axios.get(
                    `/api/auth/validate/${token.payload}`,
                );
            });
            yield put(authenticationSliceActions.setToken(token.payload));
            yield put(authenticationSliceActions.setUser(response.data));
        }
    } catch (err) {
        localStorage.removeItem('token');
        yield put(authenticationSliceActions.resetAuth());        
    }
};

export function* logoutUserSaga(token) {
    try {
        const { status } = yield call(async () => {
            return await Axios.get(
                `/api/auth/logout/${token.payload}`,
            );
        });
        localStorage.removeItem("token");
        yield put(authenticationSliceActions.resetAuth());
    } catch (err) {
        console.error("Could not logout an account because of an error: ", err);
        yield put(authenticationSliceActions.resetAuth());
    }
};


export function* authenticationRootSaga() {
    yield takeEvery(AuthenticationActions.LOGIN_USER, loginAccountSaga);
    yield takeLatest(AuthenticationActions.LOGOUT_USER, logoutUserSaga);
    yield takeLatest(AuthenticationActions.VALIDATE_TOKEN, validateTokenSaga);
}