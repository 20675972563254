import styles from './RallyCard.module.css';

const RallyCard = ({dates, header, body, slug,  image, galleryClick }) => {
    // we need to first get the header information extracted
    // then we need to iterate over the rallyDays
    // then extract the rally footer information

    const handleSelectRally = () => {
        galleryClick(slug);
    };

    return (
        <div className={`${styles.rallycard} card`} onClick={handleSelectRally}>
            {image && <img src={image} alt="rallyImage" className="card-img" />}
            <div className="card-body">
                <h5 className="card-title">{header}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{dates}</h6>
                <h5 className="card-text">{body}</h5>
            </div>
        </div>
    );
};

export default RallyCard;