import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showAdminMenu: false,
    
};

const adminMenuSlice = createSlice({
    name: "adminMenu",
    initialState,
    reducers: {
        setShowAdminMenu: (state, action) => {
            state.showAdminMenu = action.payload;
        },
        resetAdminHomeMenu: (state) => {
            state.showAdminMenu = false;
        }
    },
});

export const adminHomeSliceActions = adminMenuSlice.actions;

export default adminMenuSlice.reducer;