import { Fragment } from 'react';
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { navigationSliceActions } from '../../../../../../../store';

const AdminMenu = () => {
  const { open } = useSelector((state) => state.navigation);
  const dispatch = useDispatch();
  const handleCloseMenuClick = () => {
    if (open) dispatch(navigationSliceActions.setOpen(false));
  };
    return (
        <Fragment>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to={'/admin'} onClick={handleCloseMenuClick}>Admin Menu</NavDropdown.Item>
        </Fragment>
    )};

export default AdminMenu;