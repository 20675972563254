import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerSliceActions, navigationSliceActions } from "../../../../../store";


const LoginRegister = () => {
  const { open } = useSelector((state) => state.navigation);
  const dispatch = useDispatch();
  const onRegisterClickHandler = () => {
    dispatch(registerSliceActions.resetForm());
  };
  const onClickHandler = () => {
    if (open) dispatch(navigationSliceActions.setOpen(false));
  };
    return (
      <Nav onClick={onClickHandler}>
        <Nav.Link
          as={Link}
          to={"/login"}
        >
          Login
        </Nav.Link>
        <Nav.Link
          onClick={onRegisterClickHandler}
          as={Link}
          to={"/register"}
        >
          Register
        </Nav.Link>
      </Nav>
    );
};

export default LoginRegister;