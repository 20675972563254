import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { accountSlice, authenticationSlice, adminHomeSlice, registerSlice, loginSlice, rallySlice, checkoutSlice, navigationSlice } from "./slices";

import { accountRootSaga, authenticationRootSaga, rallyRootSaga, checkoutRootSaga } from './middleware';


const sagaMiddleWare = createSagaMiddleware();

const store = configureStore({
    reducer: {
        account: accountSlice,
        authentication: authenticationSlice,
        adminHome: adminHomeSlice,
        register: registerSlice,
        login: loginSlice,
        rally: rallySlice,
        checkout: checkoutSlice,
        navigation: navigationSlice
    },
    middleware: () => [sagaMiddleWare]
});

sagaMiddleWare.run(accountRootSaga);
sagaMiddleWare.run(authenticationRootSaga);
sagaMiddleWare.run(rallyRootSaga);
sagaMiddleWare.run(checkoutRootSaga);

export default store;