import { TopLevel } from "../../../../../Layout";
import { useSelector } from "react-redux";
import { Alert } from "react-bootstrap";


const RegisterFinished = props => {

    const { registrationSuccess, registrationError } = useSelector(
      (state) => state.account,
    );

    return (
        <TopLevel>
            <h1>Registration Received</h1>
            <p>We received your request to register an account.</p>
            <p>Please check your email for further instructions on validating your email address.</p>
            {registrationSuccess && <Alert variant="success">Email sent successfully!</Alert> }
            {registrationError && <Alert variant="danger">Something went wrong!</Alert>}
        </TopLevel>
    );
};

export default RegisterFinished;