

export const AccountActions = {
  REGISTER_ACCOUNT: "REGISTER_ACCOUNT",
  CHECK_TOKEN: "CHECK_TOKEN",
};

export function registerAccount(payload) {
  return {
    type: AccountActions.REGISTER_ACCOUNT,
    payload,
  };
}


export function checkToken(payload) {
  return {
    type: AccountActions.CHECK_TOKEN,
    payload,
  };
}
