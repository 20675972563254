import { Fragment } from "react";
import { NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { authenticationSliceActions, loginSliceActions, navigationSliceActions, accountSliceActions } from "../../../../../../../store";

const LogoutUser = () => {
    const dispatch = useDispatch();
  const { open } = useSelector((state) => state.navigation);
    const handleLogout = () => {
        localStorage.removeItem("token");
        if (open) dispatch(navigationSliceActions.setOpen(false));
        dispatch(authenticationSliceActions.resetAuth());
        dispatch(accountSliceActions.resetAccount());
        dispatch(loginSliceActions.clearSubmit());
    };

    return (
        <Fragment>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
        </Fragment>
    );
};

export default LogoutUser;