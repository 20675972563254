import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clientSecret: "",
    sessionID: "",
    showModal: false,
    customerId: "",
};

const checkoutSlice = createSlice({
    name: "checkout",
    initialState,
    reducers: {
        setSession: (state, action) => {
            const { client_secret, id } = action.payload;
            state.clientSecret = client_secret;
            state.sessionID = id;
        },
        clearSession: (state) => {
            state.clientSecret = "";
            state.sessionID = "";
        },
        setCustomerId: (state, action) => {
            state.customerId = action.payload;
        },
        clearCustomerId: (state) => {
            state.customerId = "";
        },
        setShowModal: (state, action) => {
            state.showModal = action.payload;
        },
        setHideModal: (state) => {
            state.showModal = false;    
        },
    },
});

export const checkoutSliceActions = checkoutSlice.actions;

export default checkoutSlice.reducer;