import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email: "",
    password: "",
    formSubmitted: false,
    formTimeLeft: 20
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
        },
        setFormSubmitted: (state, action) => {
            state.formSubmitted = action.payload;
        },
        setFormTimeLeft: (state, action) => {
            state.formTimeLeft = action.payload;
        },
        resetForm: (state) => {
            state.email = "";
            state.password = "";
        },
        clearSubmit: (state) => {
            state.formSubmitted = false;
            state.formTimeLeft = 20;
        },
    }
});

export const loginSliceActions = loginSlice.actions;
export default loginSlice.reducer;