import { useSelector, useDispatch } from "react-redux";
import { Fragment } from 'react';
import { LoginSuccessful, LoginForm, AlreadyLoggedIn } from "./Components";
import { loginSliceActions, loginUser, authenticationSliceActions } from "../../../../../store";

const Login = () => {
  
  const { token, loginSuccess } = useSelector(state => state.authentication);
  const { email, password, formSubmitted } = useSelector((state) => state.login);

  const dispatch = useDispatch();


  const resetFormHandler = () => {
    dispatch(loginSliceActions.resetForm());
  };

  const emailChangeHandler = (value) => {
    dispatch(loginSliceActions.setEmail(value));
  };

  const passwordChangeHandler = (value) => {
    dispatch(loginSliceActions.setPassword(value));
  };

  const submitHandler = () => {
    const account = {
      email,
      password,
    };
    dispatch(loginSliceActions.setFormSubmitted(true));
    dispatch(loginUser(account));
    dispatch(loginSliceActions.resetForm());
  }

  const resetSubmit = () => {
    dispatch(loginSliceActions.clearSubmit());
    dispatch(authenticationSliceActions.changeAuthenticationFailed(false));
  };


  return (
    <Fragment>
      {(loginSuccess && token) ? <LoginSuccessful /> : ((token || localStorage.getItem('token'))? <AlreadyLoggedIn /> : <LoginForm setEmailHandler={emailChangeHandler} setPasswordHandler={passwordChangeHandler} setSubmitHandler={submitHandler} formSubmitted={formSubmitted} setFormReset={resetFormHandler} setClearSubmit={resetSubmit} email={email} />)}
    </Fragment>
  );
};

export default Login;