import { useSelector } from "react-redux";
import { TopLevel } from "../../../../Layout";
import moment from "moment";

const AlreadyLoggedIn = () => {
    const { email, firstName, createdDate, lastName  } = useSelector(state => state.authentication);
    const Introduction = () => {
        if (firstName && lastName) { 
            return <h3>Hello {firstName} {lastName}!</h3>;
        }
        if (email) {
            return <h3>Hello {email}!</h3>;
        }
        return <h3>Hello!</h3>;
    };
    return (
        <TopLevel>
            <Introduction />
            <p>You are already logged in.  Since you're here...here is some interesting stuff about your account</p>
            {createdDate && <p>You joined {moment(createdDate).format("MMMM Do YYYY")}</p>}
        </TopLevel>
    );
};

export default AlreadyLoggedIn;