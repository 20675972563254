import { TopLevel } from "../../../Layout";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { RegisterForm, RegisterFinished } from "./Components";


const Register = props => {

    const { registrationSent } = useSelector((state) => state.account);
    const { token } = useSelector((state) => state.authentication);
    const navigate = useNavigate();

    if (token) {
      navigate('/');
    }
    


    
    return (
      <TopLevel>
        {registrationSent ? <RegisterFinished /> : <RegisterForm />}
      </TopLevel>
    );
};

export default Register;