import { Fragment } from "react";
import { UserMenu } from './UserList';
import { AdminMenu } from "./AdminList";
import { LogoutUser } from "./UserList";

const LoggedInComponents = ({ roles }) => {

    // let's find out if the rols contains certain things
    const isAdmin = roles.includes("admin");
    const isUser = roles.includes("user");
    //const isWebmaster = roles.includes("webmaster");

    return (
      <Fragment>
        {isUser && <UserMenu />}
        {isAdmin && <AdminMenu />}
        <LogoutUser />
      </Fragment>
    );
};

export default LoggedInComponents;