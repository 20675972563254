import { TopLevel } from "../../../Layout";
import { Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { accountSliceActions } from "../../../../../store";
import { checkToken } from "../../../../../store";

const Validate = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useParams();
  const { validationError, validationSuccess, validationToken } = useSelector(
    (state) => state.account,
  );

  useEffect(() => {
    console.log("entered validate", token);
    if (!token) {
      navigate("/");
    }
    if (!validationToken) {
      dispatch(checkToken(token));
      dispatch(accountSliceActions.setValidationToken(token));
    }
    if (validationSuccess) {
      navigate("/login");
    }
  }, [token, validationSuccess, validationToken, dispatch, navigate]);

  return (
    <TopLevel>
      <h1>Validating account</h1>
      <p>Please wait...</p>
      <p>You should be directed to the login page unless there is a problem.</p>
      {validationError && <Alert variant="danger">Something went wrong!</Alert>}
    </TopLevel>
  );
};

export default Validate;
