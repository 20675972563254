import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { NavLoginRegister } from './Components';
import { closeModule, navigationSliceActions } from '../../../store';
const NavBar = props => {
  const { showAdminMenu } = useSelector(state => state.adminHome);
  const { open } = useSelector(state => state.navigation);
  const dispatch = useDispatch();
  const handleCloseModalClick = () => {
    dispatch(closeModule());
  }

  const handleOpenMenu = () => {
    dispatch(navigationSliceActions.toggleOpen());
  };

  const closeOpenMenu = () => {
    if (open) dispatch(navigationSliceActions.setOpen(false));
  };
  
    return (
      <Navbar
        onClick={handleCloseModalClick}
        expand="lg"
        className="bg-body-tertiary"
        fixed="top"
        data-bs-theme="dark"
      >
        <Container>
          <Navbar.Brand
            as={Link}
            to={"/"}
            onClick={closeOpenMenu}
          >
            <img
              alt="Rips Rallies, LLC Logo"
              src="https://agxzqngooq.cloudimg.io/_cloud_/logos/Logos_Website_ripsrallieslogo.jpg?w=50"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={handleOpenMenu}
          />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            in={open}
          >
            <Nav
              className="me-auto"
              onClick={closeOpenMenu}
            >
              <Nav.Link
                as={Link}
                to={"/"}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={"/rallies"}
              >
                Rallies
              </Nav.Link>
            </Nav>
            <NavLoginRegister  />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
};

export default NavBar;