import { TopLevel } from '../../Layout';
import styles from './Home.module.css';

const AdminHome = () => {
    return (
        <TopLevel>
            <h1>Admin Home</h1>
        </TopLevel>
    );
};

export default AdminHome;