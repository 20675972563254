import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { navigationSliceActions } from "../../../../../../../store";


const UserMenu = () => {
  const { open } = useSelector((state) => state.navigation);
  const dispatch = useDispatch();
  const handleCloseMenuClick = () => {
    if (open) dispatch(navigationSliceActions.setOpen(false));
  };
    return (
        <NavDropdown.Item as={Link} to={'/profile'} onClick={handleCloseMenuClick}>Profile</NavDropdown.Item>
    );
};

export default UserMenu;