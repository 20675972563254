import { TopLevel } from '../../../../../Layout';
import { Form, Button } from "react-bootstrap";
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { accountSliceActions, registerAccount, registerSliceActions } from "../../../../../../../store";
import { useNavigate } from 'react-router-dom';
import { Check, XCircle } from "react-bootstrap-icons";


import styles from './RegisterForm.module.css';

const RegisterForm = props => {

  const { firstName, lastName, email, password, firstNameValid, lastNameValid, emailValid, passwordValid, confirmPasswordValid, validRegistration, passwordLength, passwordLower, passwordUpper, passwordNumber, passwordSymbol } = useSelector(state => state.register);
  const { open } = useSelector((state) => state.navigation);
        


    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
    }, [open]);

    const handleSubmit = (e) => {
      e.preventDefault();
      const account = {
        firstName,
        lastName,
        email,
        password,
      };

      dispatch(registerAccount(account));
      dispatch(registerSliceActions.resetForm());
      dispatch(accountSliceActions.setRegistrationSent());
    };

    const handleFirstNameChange = (e) => {
      dispatch(registerSliceActions.setFirstName(e.target.value));
    };

    const handleLastNameChange  = (e) => {
      dispatch(registerSliceActions.setLastName(e.target.value));
    };

    const handleEmailChange = (e) => {
      dispatch(registerSliceActions.setEmail(e.target.value));

    };

    const handlePasswordChange = (e) => {
      dispatch(registerSliceActions.setPassword(e.target.value));
    };

    const handleNavigateToLogin = () => {
        navigate('/login');
    };

    const handleConfirmPasswordChange = (e) => {
      dispatch(registerSliceActions.setConfirmPassword(e.target.value));
    };

    const IsValidPassword = () => {
      if (passwordValid) {
        return (
          <li className="text-success">
            <Check /> Password looks good!
          </li>
        );
      } else {
        return (
          <>
          {!passwordLength && 
          <li className="text-danger">
            <XCircle /> Password not long enough
          </li>}
          {!passwordLower && 
          <li className="text-danger">
            <XCircle /> Password needs at least 1 lowercase
          </li>}
          {!passwordUpper && 
          <li className="text-danger">
            <XCircle /> Password needs at least 1 uppercase
          </li>}
          {!passwordNumber && 
          <li className="text-danger">
            <XCircle /> Password needs at least 1 number
          </li>}
          {!passwordSymbol && 
          <li className="text-danger">
            <XCircle /> Password needs at least 1 symbol
          </li>}
          </>
        );
      }
    };
    return (
      <TopLevel>
        <h1>Register an Account</h1>
        <p>All fields are required</p>
        <Form onSubmit={handleSubmit}>
          <Form.Group
            controlId="formFirstName"
            className="mb-3 mt-2"
          >
            <Form.Label>First Name</Form.Label>
            <Form.Control
              required
              isValid={firstNameValid}
              type="text"
              placeholder="First name"
              onChange={handleFirstNameChange}
            />
            <ul className={styles["no-bullets"]}>
              {firstNameValid ? (
                <li className="text-success">
                  <Check /> First name looks good
                </li>
              ) : (
                <li className="text-danger">
                  <XCircle /> First name must be at least 2 characters long
                </li>
              )}
            </ul>
          </Form.Group>
          <Form.Group
            controlId="formLastName"
            className="mb-3"
          >
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              required
              isValid={lastNameValid}
              type="text"
              placeholder="Last name"
              onChange={handleLastNameChange}
            />
            <ul className={styles["no-bullets"]}>
              {lastNameValid ? (
                <li className="text-success">
                  <Check /> Last name looks good
                </li>
              ) : (
                <li className="text-danger">
                  <XCircle /> Last name must be at least 2 characters long
                </li>
              )}
            </ul>
          </Form.Group>
          <Form.Group
            controlId="formEmail"
            className="mb-3"
          >
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              isValid={emailValid}
              type="email"
              placeholder="Email"
              onChange={handleEmailChange}
            />
            <ul className={styles["no-bullets"]}>
              {emailValid ? (
                <li className="text-success">
                  <Check /> Email looks good
                </li>
              ) : (
                <li className="text-danger">
                  <XCircle /> Email must be valid
                </li>
              )}
            </ul>
          </Form.Group>
          <Form.Group
            controlId="formPassword"
            className="mb-3"
          >
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              isValid={passwordValid}
              type="password"
              placeholder="Password"
              onChange={handlePasswordChange}
            />
            <ul className={styles["no-bullets"]}>
              <IsValidPassword />
            </ul>
          </Form.Group>
          <Form.Group
            controlId="formConfirmPassword"
            className="mb-5"
          >
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              required
              isValid={confirmPasswordValid}
              type="password"
              placeholder="Confirm password"
              onChange={handleConfirmPasswordChange}
            />
            <ul className={styles["no-bullets"]}>
              {confirmPasswordValid ? (
                <li className="text-success">
                  <Check /> Passwords must match
                </li>
              ) : (
                <li className="text-danger">
                  <XCircle /> Passwords must match
                </li>
              )}
            </ul>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={!validRegistration}
            className="btn-lg w-100"
          >
            Register
          </Button>
        </Form>
        <button
          onClick={handleNavigateToLogin}
          className="btn btn-lg w-100 mt-5 btn-secondary"
        >
          Login
        </button>
      </TopLevel>
    );
};

export default RegisterForm;