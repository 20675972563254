import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { TopLevel } from "../../Layout";
import { NavBar } from "../../Navigation";

const Dashboard = () => {
    return (
        <Fragment>
            <NavBar />
            <TopLevel>
                <Outlet />
            </TopLevel>
        </Fragment>
    );
};

export default Dashboard;