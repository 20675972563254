import { TopLevel } from "../../../Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authenticationSliceActions } from "../../../../../store";

const Error = () => {
  const location = useLocation();
  const { pathname } = location;
  const { token, loginSuccess, redirectUrl } = useSelector(
    (state) => state.authentication,
  );
  const navigate = useNavigate();
  if (loginSuccess && token) {
    authenticationSliceActions.setLoginSuccess(false); // we don't want to end up here again;
    if (redirectUrl) navigate(redirectUrl);
    navigate("/");
  }
  console.error(`Route was not found at pathname: ${pathname}`);
  return (
    <TopLevel>
      <h1>Oops!</h1>
      <p>Sorry, you tried looking for a page that didn't exist.</p>
      <p>But, it has been logged.</p>
      <p>Please try the request again, or let us know about it.</p>
    </TopLevel>
  );
};

export default Error;
