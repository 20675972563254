import { createSlice } from "@reduxjs/toolkit";
import validator from 'validator';

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    validRegistration: false,
    firstNameValid: false,
    lastNameValid: false,
    emailValid: false,
    passwordUpper: false,
    passwordLower: false,
    passwordNumber: false,
    passwordSymbol: false,
    passwordLength: false,
    passwordValid: false,
    confirmPasswordValid: false,
};

const registerSlice = createSlice({
    name: "register",
    initialState,
    reducers: {
        setFirstName: (state, action) => {
            state.firstName = action.payload;
            state.firstNameValid = validator.isAlpha(state.firstName) && validator.isLength(state.firstName, { min: 2, max: 30 });
            state.validRegistration = isValidForm(state.firstNameValid, state.lastNameValid, state.emailValid, state.passwordValid, state.confirmPasswordValid);
        },
        setLastName: (state, action) => {
            state.lastName = action.payload;
            state.lastNameValid = validator.isAlpha(state.lastName) && validator.isLength(state.lastName, { min: 2, max: 30 });
            state.validRegistration = isValidForm(
              state.firstNameValid,
              state.lastNameValid,
              state.emailValid,
              state.passwordValid,
              state.confirmPasswordValid,
            );
        },
        setEmail: (state, action) => {
            state.email = action.payload;
            state.emailValid = validator.isEmail(state.email);
            state.validRegistration = isValidForm(
              state.firstNameValid,
              state.lastNameValid,
              state.emailValid,
              state.passwordValid,
              state.confirmPasswordValid,
            );
        },
        setPassword: (state, action) => {
            const upperCase = /([A-Z])/g;
            const lowerCase = /([a-z])/g;
            const number = /([0-9])/g;
        
            
            state.password = action.payload;
            state.passwordLength =
              state.password.length > 8 && state.password.length < 30;
            state.passwordLower = lowerCase.test(state.password);
            state.passwordUpper = upperCase.test(state.password);
            state.passwordNumber = number.test(state.password);
            state.passwordSymbol = state.password.length > 0 && !validator.isAlphanumeric(state.password);
            state.passwordValid = isValidPassword(
                state.passwordLength,
                state.passwordLower,
                state.passwordUpper,
                state.passwordNumber,
                state.passwordSymbol);
            state.validRegistration = isValidForm(
              state.firstNameValid,
              state.lastNameValid,
              state.emailValid,
              state.passwordValid,
              state.confirmPasswordValid,
            );
        },
        setConfirmPassword: (state, action) => {
            state.confirmPassword = action.payload;
            state.confirmPasswordValid = state.password === state.confirmPassword;
            state.validRegistration = isValidForm(
              state.firstNameValid,
              state.lastNameValid,
              state.emailValid,
              state.passwordValid,
              state.confirmPasswordValid,
            );
        },
        resetForm: (state) => {
            state.firstName = "";
            state.lastName = "";
            state.email = "";
            state.password = "";
            state.confirmPassword = "";
            state.validRegistration = false;
            state.firstNameValid = false;
            state.lastNameValid = false;
            state.emailValid = false;
            state.passwordValid = false;
            state.passwordLength = false;
            state.passwordLower = false;
            state.passwordUpper = false;
            state.passwordNumber = false;
            state.passwordSymbol = false;
            state.confirmPasswordValid = false;
        }
    }
});

const isValidForm = (validFirstName, validLastName, validEmail, validPassword, validConfirmPassword) => {
    return validFirstName && validLastName && validEmail && validPassword && validConfirmPassword;
};

const isValidPassword = (passWordLength, passwordLower, passwordUpper, passwordNumber, passwordSymbol) => {
    return passWordLength && passwordLower && passwordUpper && passwordNumber && passwordSymbol;
};

export const registerSliceActions = registerSlice.actions;
export default registerSlice.reducer;