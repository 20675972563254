
import styles from './Home.module.css';

const Home = () => {

    const videoUrl =
      "https://ripsralliescdn-hbapcffrc3e8fpeb.z01.azurefd.net/videos/promo_video.mp4";
    return (
    
      <video className={styles.videoPlayer} autoPlay={true} muted={true} loop={true} src={videoUrl} controls={false} preload="none" />
    
    );
};

export default Home;