import { useSelector, useDispatch } from "react-redux";
import { TopLevel } from "../../../../Layout";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { authenticationSliceActions } from "../../../../../../store";

const LoginSuccessful = () => {
    const { email, firstName, lastName, loginSuccess, redirectUrl  } = useSelector(state => state.authentication);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Introduction = () => {
        if (firstName && lastName) { 
            return <h3>Hello {firstName} {lastName}!</h3>;
        }
        if (email) {
            return <h3>Hello {email}!</h3>;
        }
    };
    useEffect(() => {
        if (loginSuccess) {
            dispatch(authenticationSliceActions.setLoginSuccess(false));
            if (redirectUrl) {
                navigate(redirectUrl);
                return;
            } 
            navigate("/");
        }
    }, [loginSuccess]);
    return (
        <TopLevel>
            <Introduction />
            <p>Login was successful. You'll now be re-directed to your next page.</p>
        </TopLevel>
    );
};

export default LoginSuccessful;