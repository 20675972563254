import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  validationSuccess: false,
  validationToken: "",
  lastTokenCheck: Date.now(),
  validationError: false,
  registrationSuccess: false,
  registrationError: false,
  registrationSent: false,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setValidationToken: (state, action) => {
      state.validationToken = action.payload;
    },
    changeValidationSuccess: (state, action) => {      
      state.validationSuccess = action.payload === 200;
      state.validationError = action.payload !== 200;
    },
    setRegistrationSent: (state) => {
      state.registrationSent = true;
    },
    setLastTokenCheck: (state) => {
      state.lastTokenCheck = Date.now();
    },
    setRegistrationStatus: (state, action) => {
      console.log(action.payload);
      if (action.payload === 200) state.registrationSuccess = true;
      else state.registrationError = true;
    },
    changeRegistrationSuccess: (state, action) => {
      state.registrationSuccess = action.payload;
    },
    changeLoginSuccess: (state, action) => {
      state.loginSuccess = action.payload;
    },
    resetAccount: (state) => {
      state.validationSuccess = false;
      state.validationToken = "";
      state.validationError = false;
      state.registrationSuccess = false;
      state.registrationError = false;
      state.registrationSent = false;
      state.lastTokenCheck = Date.now();
    },
  },
});

export const accountSliceActions = accountSlice.actions;

export default accountSlice.reducer;
