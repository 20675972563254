import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    rallies: [],
    selectedRally: "",
    rallySlug: "",
};

const rallySlice = createSlice({
    name: "rally",
    initialState,
    reducers: {
        setRallies: (state, action) => {
            state.rallies = action.payload;
        },
        setRally: (state, action) => {
            state.selectedRally = action.payload;
        },
        setRallySlug: (state, action) => {
            state.rallySlug = action.payload;
        },
        removeRally: (state) => {
            state.selectedRally = "";
        },
    },
});

export const rallySliceActions = rallySlice.actions;

export default rallySlice.reducer;