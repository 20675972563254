import React from "react";
import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import "./index.css";


import { validateToken } from "./store";

import { store } from "./store";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// Bootstrap JS
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import  App  from "./App";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

store.dispatch(validateToken(localStorage.getItem("token")));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
  <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>,
);
