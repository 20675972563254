import { Container, Row, Col } from "react-bootstrap";

const TopLevel = props => {
    return (
      <Container>
        <Row className="jistify-content-md-center">
          <Col>
            {props.children}
          </Col>
        </Row>
      </Container>
    );
};

export default TopLevel;