import { takeLeading, call, put } from "redux-saga/effects";
import { checkoutSliceActions } from "../../slices";
import { CheckoutActions } from "../actions";
import Axios from 'axios';

export function* createPaymentIntentSaga(payload) {
  try {
    const { data } = yield call(async () => {
        return await Axios.post('/api/checkout', payload.payload);
    });
    const { client_secret, id } = data;
    yield put(checkoutSliceActions.setSession({ client_secret, id }));
  } catch (error) {
    console.log(`Error creating payment intent: ${error}`);
  }
}

export function* searchForCustomerSaga(payload) {
    try {
        const { name, email } = payload.payload;
        const { data } = yield call(async () => {
            return await Axios.get(`/api/checkout/customer/${email}`);
        });
        if (data.data && data.data.length > 0) yield put(checkoutSliceActions.setCustomerId(data.data[0].id));
        else {
            const newCustomer = yield call(async () => {
                return await Axios.post('/api/checkout/customer', { name, email });
            });
            yield put(checkoutSliceActions.setCustomerId(newCustomer.data.id));
        }
    } catch (error) {
        console.log(`Error searching for customer: ${error}`);
    }
}

export function* createCustomerSaga(payload) {
    try {
        const { data} = yield call(async () => {
            return await Axios.post('/api/checkout/customer', payload.payload);
        });
        yield put(checkoutSliceActions.setCustomerId(data.data.id));
    } catch (error) {
        console.log(`Error creating customer: ${error}`);
    }
}

export function* setOpenModalSaga() {
    yield put(checkoutSliceActions.setShowModal(true));
}

export function* setHideModalSaga() {
    yield put(checkoutSliceActions.setHideModal());
}

export function* checkoutRootSaga() {
    yield takeLeading(CheckoutActions.CREATE_PAYMENT_INTENT, createPaymentIntentSaga);
    yield takeLeading(CheckoutActions.SEARCH_FOR_CUSTOMER, searchForCustomerSaga);
    yield takeLeading(CheckoutActions.CREATE_CUSTOMER, createCustomerSaga);
    yield takeLeading(CheckoutActions.OPEN_MODULE, setOpenModalSaga);
    yield takeLeading(CheckoutActions.CLOSE_MODULE, setHideModalSaga);
}
