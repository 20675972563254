import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: "",
    email: "",
    firstName: "",
    userId: "",
    createdDate: "",
    redirectUrl: "",
    lastName: "",
    authenticationFailed: false,
    loginSuccess: false,
    roles: [],
};

const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        changeAuthenticationFailed: (state, action) => {
            state.authenticationFailed = action.payload;
        },
        setLoginSuccess: (state, action) => {
            state.loginSuccess = action.payload;
        },
        setFormSubmitted: (state, action) => {
            state.formSubmitted = action.payload;
            state.authenticationFailed = false;
        },
        setRedirectUrl: (state, action) => {
            state.redirectUrl = action.payload;
        },
        resetAuth: (state) => {
            state.token = "";
            state.email = "";
            state.firstName = "";
            state.lastName = "";
            state.redirectUrl = "";
            state.createdDate = "";
            state.userId = "";
            state.authenticationFailed = false;
            state.loginSuccess = false;
            state.roles = [];
        },
        setUser: (state, action) => {
            const { firstName, lastName, email, roles, createdDate, userId } = action.payload;
            state.firstName = firstName;
            state.lastName = lastName;
            state.email = email;
            state.roles = roles;
            state.createdDate = createdDate;
            state.userId = userId;
        }
    },
});

export const authenticationSliceActions = authenticationSlice.actions;

export default authenticationSlice.reducer;