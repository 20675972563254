import { TopLevel } from "../../Layout";

const Profile = props => {
    return (
        <TopLevel>
            <h1>Profile</h1>
        </TopLevel>
    );
};

export default Profile;