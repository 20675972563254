import { TopLevel } from "../../../../Layout";
import RallyCard from "./RallyCard/RallyCard";
import { rallySliceActions } from "../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Rallies = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleRallyClick = (rallySlug) => {
    dispatch(rallySliceActions.setRallySlug(rallySlug));
    navigate(`/rallies/${rallySlug}`);
  };

  const { rallies } = useSelector((state) => state.rally);

  const RallyCards = () => 
        rallies.map((rally) => {
          return (
            <RallyCard
              key={rally.slug}
              {...rally}
              galleryClick={handleRallyClick}
            />
          );
        });
      
  

  return (
    <TopLevel>
      <div className="d-flex flex-wrap">
        {(rallies && rallies.map) && <RallyCards />}
      </div>
    </TopLevel>
  );
};

export default Rallies;
