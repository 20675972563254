import { useSelector, useDispatch } from "react-redux";
import { useEffect, Fragment } from "react";
import Checkout from "./Components/Checkout";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import styles from "./RallyDetails.module.css";

import { getRallyBySlug, createPaymentIntent, searchForCustomer, openModule, closeModule } from "../../../../../../../store";
const key = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(key);

const RallyDetails = ({ rallySlug }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRallyBySlug(rallySlug));
  }, [dispatch, rallySlug]);
  const { token, email, firstName, lastName } = useSelector(
    (state) => state.authentication,
  );


  const name = `${firstName} ${lastName}`;
  const { selectedRally } = useSelector((state) => state.rally);
  const { clientSecret, showModal, customerId } = useSelector((state) => state.checkout);


  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
   });


  const RallyDays = () => {
    const { days } =
      selectedRally;
    return days.map((day) => {
      return (
        <div key={day.day}>
          <h3>{day.day}:</h3>
          {day.locations && (
            <ul className="list-unstyled">
              {day.locations.map((location) => {
                return (
                  <li
                    key={location.location}
                    className={styles.daysContent}
                  >
                    <div className="container">
                      <div className="row align-items-start mb-3">
                        <div className="col-2">
                          <h5>{location.location}</h5>
                        </div>
                        <div className={`col-8 ${styles['mobile-col']}`}>
                          <p className={styles.daysContent}>
                            {location.details}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          <div className="container mt-3 mb-5">
            <p className={styles.daysContent}>{day.details}</p>
          </div>
        </div>
      );
    });
  };

  const RallyIncluded = () => {

  const { included } =
    selectedRally;
  const { meals, hotelStays, rallyStickers, swagBag, eventTickets } = included;
    let events;
    let rallyEvents = false;
    if (eventTickets) {
        rallyEvents = true;
        events = eventTickets.map((event) => {
            return (
                    <div className="row align-items-start mb-3" key={event.slug}>
                        <div className="col-6">
                        <div className="container">
                            {event.event}</div>
                        </div>
                        <div className={`col-2 ${styles['mobile-col']}`}>
                                {event.tickets}
                        </div>
                    </div>
            );
        });
    }
    const rallyLunch = meals && meals.lunches;
    const rallyDinner = meals && meals.dinners;
    const rallyHotelStays = hotelStays;
    return (
      <ul className="list-unstyled">
        {rallyEvents && (
            <li>
                <div className="container">
                    <div className="row">
                        <div className="col-3">Event Tickets:</div>
                        <div className={`col-7 ${styles['mobile-col']}`}>{events}</div>
                    </div>
                    
                </div>
            </li>
        )}
        {rallyLunch && (
          <li>
            <div className="container">
              <div className="row">
                <div className="col-5">Lunches:</div>
                <div className={`col-6`}>{meals.lunches}</div>
              </div>
            </div>
          </li>
        )}
        {rallyDinner && (
          <li>
            <div className="container">
              <div className="row">
                <div className="col-5">Dinners:</div>
                <div className="col-6">{meals.dinners}</div>
              </div>
            </div>
          </li>
        )}
        {rallyHotelStays && (
          <li>
            <div className="container">
              <div className="row">
                <div className="col-5">Hotel Nights:</div>
                <div className="col-6">{hotelStays}</div>
              </div>
            </div>
          </li>
        )}
        {swagBag && (
            <li>
                <div className="container">
                    <div className="row">
                        <div className="col-5">Swag Bag:</div>
                        <div className="col-6">Yes</div>
                    </div>
                </div>
            </li>
        )}
        {rallyStickers && (
            <li>
                <div className="container">
                    <div className="row">
                        <div className="col-5">Rally Stickers:</div>
                        <div className="col-6">Yes</div>
                    </div>
                </div>
            </li>
        )}
      </ul>
    );
  };

  const LoggedinAndStripe = ({ price, header }) => {
    const handleOpenModalClick = () => {
      dispatch(openModule());
    };

    const handleCloseModalClick = () => {
      dispatch(closeModule());
    };
    const { slug } = selectedRally;
    useEffect(() => {
      if (!customerId) {
        dispatch(searchForCustomer({ email, name }));
      }
      else {
        if (email && !clientSecret) dispatch(createPaymentIntent({ price, customerId }));
      }
    }, [price]);
      if (clientSecret) {
        const options = {
          clientSecret: `${clientSecret}`,
        };
        if (showModal) {
          return (
            <Elements stripe={stripePromise} options={options}>
              <Checkout setHandleCloseModal={handleCloseModalClick} price={USDollar.format(price)} />
            </Elements>
          );
        } 
      } 
      return (
        <div>
          <button onClick={handleOpenModalClick} className="btn btn-primary w-25">Register for {header}</button>
        </div>
      );
  };

  const RallyCardDetails = () => {

    const { header, dates, pricePerVehicle, maxVehicles, image } =
      selectedRally;
    return (
      <div>
        {image && (
          <img
            src={image}
            alt="rally"
            className="img-fluid"
          />
        )}
        <h1>{header}</h1>

        <h4>{dates}</h4>
        <RallyDays />
        <div className="container mt-3 mb-5">
          <div className="row align-items-start mb-3">
            <div className="col-1">
              <h5>Included:</h5>
            </div>
            <div className={`col-10 ${styles['mobile-col']}`}>
              <RallyIncluded />
            </div>
          </div>
          <div className="row align-items-start mb-3">
            <div className="col-2">
              <h5>Price Per Vehicle:</h5>
            </div>
            <div className="col-8">
              <p>{USDollar.format(pricePerVehicle)}</p>
            </div>
          </div>
          <div className="row align-items-start mb-3">
            <div className="col-2">
              <h5>Max Vehicles:</h5>
            </div>
            <div className="col-8">
              <p>{maxVehicles}</p>
            </div>
          </div>
        </div>
        {token && (
          <div className="container mt-3 mb-5">
            <LoggedinAndStripe price={pricePerVehicle} header={header} />
          </div>
        )}
        {!token && (
          <div className="container mt-3 mb-3">
            Login or create an account to register for this rally.
          </div>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      {selectedRally && <RallyCardDetails /> }   
    </Fragment>
  );
};

export default RallyDetails;
