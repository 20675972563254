import { takeLeading, call, put } from "redux-saga/effects";

import { rallySliceActions } from "../../slices";

import { RallyActions } from "../actions";
import Axios from 'axios';

export function* getRallyListSaga() {
    try {
        const data = yield call(async () => {
            return await Axios.get('/api/rallies');
        });
        yield put(rallySliceActions.setRallies(data.data));
    } catch (err) {
        console.error("Could not get rallies because of an error: ", err);
    }
}

export function* getRallyBySlugSaga(slug) {
    try {
        const data = yield call(async () => {
            return await Axios.get(`/api/rallies/rally/${slug.payload}`);
        });
        yield put(rallySliceActions.setRally(data.data));
    } catch (err) {
        console.error("Could not get rally by slug because of an error: ", err);
    }
}

export function* registerForRallySaga(payload) {
    try {
        const { data } = yield call(async () => {
            return await Axios.post('/api/checkout', payload.payload);
        });
        const { client_secret, id } = data;
        yield put(rallySliceActions.setSession({ client_secret, id }));
    } catch (err) {
        console.error("Could not register for rally because of an error: ", err);
    }
}



export function* rallyRootSaga() {
    yield takeLeading(RallyActions.GET_RALLIES, getRallyListSaga);
    yield takeLeading(RallyActions.GET_RALLY_BY_SLUG, getRallyBySlugSaga);
    yield takeLeading(RallyActions.REGISTER_FOR_RALLY, registerForRallySaga);
}