export const AuthenticationActions = {
    SET_TOKEN: "SET_TOKEN",
    RESET_AUTH: "RESET_AUTH",
    SET_USER: "SET_USER",
    VALIDATE_TOKEN: "VALIDATE_TOKEN",
    LOGIN_USER: "LOGIN_USER",
    LOGOUT_USER: "LOGOUT_USER",
};

export function validateToken(payload) {
    return {
        type: AuthenticationActions.VALIDATE_TOKEN,
        payload,
    };
}

export function setToken(payload) {
    return {
        type: AuthenticationActions.SET_TOKEN,
        payload,
    };
}

export function resetAuth() {
    return {
        type: AuthenticationActions.RESET_AUTH,
    };
}

export function setUser(payload) {
    return {
        type: AuthenticationActions.SET_USER,
        payload,
    };
}

export function loginUser(payload) {
    return {
        type: AuthenticationActions.LOGIN_USER,
        payload,
    };
}

export function logoutUser() {
    return {
        type: AuthenticationActions.LOGOUT_USER,
    };
}