import { TopLevel } from "../../../../Layout";
import { Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect  } from "react";
import {
  loginSliceActions
} from "../../../../../../store";
import { Form, Button } from "react-bootstrap";

let password = '';

const LoginForm = (props) => {
  const { setEmailHandler, setPasswordHandler, formSubmitted, setSubmitHandler, setFormReset, email, setClearSubmit } = props;
  const { formTimeLeft } = useSelector((state) => state.login);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { validationSuccess } = useSelector((state) => state.account);
  const { authenticationFailed } = useSelector((state) => state.authentication);

  const escape = (!formSubmitted  && !validationSuccess) ? true : validationSuccess;

  useEffect(() => {
    if (escape) return;
      if (!formTimeLeft) {
        if (authenticationFailed) {
          setFormReset();
          setClearSubmit();
        }
        password = '';
        return;
      }
      const intervalId = setInterval(() => {
        dispatch(loginSliceActions.setFormTimeLeft(formTimeLeft - 1));
      }, 1000);
   
      return () => clearInterval(intervalId);
    
  }, [
    formTimeLeft, dispatch, escape, authenticationFailed, validationSuccess, formSubmitted, setFormReset, setClearSubmit
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitHandler();
    password = '';
    dispatch(loginSliceActions.setFormTimeLeft(20));
  };

  const handleEmailChange = (e) => {
    setEmailHandler(e.target.value);
  };

  const handlePasswordChange = (e) => {
    password = e.target.value;
  
    setPasswordHandler(password);
  };

  const handleNavigateToRegister = () => {
    navigate("/register");
  };

  return (
    <TopLevel>
      {validationSuccess && (
        <Alert variant="success">
          Your account is validated and you can log in.
        </Alert>
      )}
      {authenticationFailed && (
        <Alert variant="danger">
          Authentication has failed. For security reasons, form is disabled but will reset shortly {formTimeLeft} seconds.
        </Alert>
      )}
      <h1>Login</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group
          controlId="formEmail"
          className="mb-3 mt-2"
        >
          <Form.Label>Email address</Form.Label>
          <Form.Control
            required
            value={email}
            disabled={formSubmitted}
            type="email"
            placeholder="Enter email"
            onChange={handleEmailChange}
          />
        </Form.Group>
        <Form.Group
          controlId="formPassword"
          className="mb-3"
        >
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            disabled={formSubmitted}
            value={password}
            type="password"
            placeholder="Password"
            onChange={handlePasswordChange}
          />
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          disabled={formSubmitted}
          className="btn-lg w-100 mt-2"
        >
          Login
        </Button>
      </Form>
      <button
        onClick={handleNavigateToRegister}
        className="btn btn-lg w-100 mt-5 btn-secondary"
      >
        Register
      </button>
    </TopLevel>
  );
};

export default LoginForm;
