import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";


import { Modal } from "../../../../../../Layout";


const Checkout = ({ setHandleCloseModal, price }) => {
  
  const stripe = useStripe();
  const elements = useElements();
  console.log(window.location.href);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.href}/registered`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <Modal onToggleModal={setHandleCloseModal}>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button className="btn btn-primary mb-5 mt-5 w-75" disabled={!stripe}>Submit payment for {price}</button>
      </form>
      <button className="btn btn-secondary w-75" onClick={setHandleCloseModal}>Cancel</button>
    </Modal>
  );
};

export default Checkout;
