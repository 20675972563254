import { takeEvery, call, put, takeLeading } from "redux-saga/effects";

import { accountSliceActions } from "../../slices";
import {
  AccountActions,
} from "../actions";
import Axios from "axios";

export function* sendRegistration(account) {
  try {
    const { status } = yield call(async () => {
     return await Axios.post(
        '/api/account/register',
         { ...account.payload },
      );
    });
    yield put(accountSliceActions.setRegistrationStatus(status));
  } catch (err) {
    console.error("Could not register an account because of an error: ", err);
    yield put(accountSliceActions.setRegistrationStatus(400));
  }
}

export function* checkTokenSaga(token) {
  try {
    const { status } = yield call(async () => {
      return await Axios.put(
         `/api/account/validate/${token.payload}`
      );
    });
    console.log('validation status: ', status);
    yield put(accountSliceActions.changeValidationSuccess(status));
  } catch (err) {
    console.error("Could not check token because of an error: ", err);
    yield put(accountSliceActions.changeValidationSuccess(400));
  }
}

export function* accountRootSaga() {
  yield takeEvery(AccountActions.REGISTER_ACCOUNT, sendRegistration);
  yield takeLeading(AccountActions.CHECK_TOKEN, checkTokenSaga);
}
