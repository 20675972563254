export const CheckoutActions = {
    SEARCH_FOR_CUSTOMER: "SEARCH_FOR_CUSTOMER",
    CREATE_CUSTOMER: "CREATE_CUSTOMER",
    CREATE_PAYMENT_INTENT: "CREATE_PAYMENT_INTENT",
    OPEN_MODULE: "OPEN_MODULE",
    CLOSE_MODULE: "CLOSE_MODULE",
};

export function searchForCustomer(payload) {
    return {
        type: CheckoutActions.SEARCH_FOR_CUSTOMER,
        payload,
    };
}

export function createCustomer(payload) {
    return {
        type: CheckoutActions.CREATE_CUSTOMER,
        payload,
    };
}

export function createPaymentIntent(payload) {
    return {
        type: CheckoutActions.CREATE_PAYMENT_INTENT,
        payload,
    };
}

export function openModule() {
    return {
        type: CheckoutActions.OPEN_MODULE
    };
}

export function closeModule() {
    return {
        type: CheckoutActions.CLOSE_MODULE
    };
}