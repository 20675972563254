import { Fragment } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useParams } from "react-router-dom";

import { RallyDetails, Rallies } from "./Components";

import { getRallies } from "../../../../../store";


const RallyHandler = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getRallies());
    }, [dispatch]);
   
    const rallySlug = useParams().rallySlug;
    const RallyMount = () => {
        if (rallySlug) {
            return <RallyDetails rallySlug={rallySlug} />;
        } else 
        {
            return <Rallies />;
        }
    };
  return (
    <Fragment>
      <RallyMount />
    </Fragment>
  );
};

export default RallyHandler;
