import { Nav, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

import { LoggedInComponents } from "./Components";

const LoggedInDropDown = () => {
    const { token, email, firstName, lastName, roles } = useSelector(
      (state) => state.authentication,
    );

    const dropdownTitle = () => {
        if (firstName && lastName) {
            return `Welcome ${firstName} ${lastName}`;
        }
        if (email) {
            return `Welcome ${email}`;
        }
        if (token) {
            return `Welcome friend`;
        }
    };

    return (
        <Nav>
            <NavDropdown
                title={dropdownTitle()}
                id="collapsible-logged-dropdown"
            >
                <LoggedInComponents roles={roles} />
            </NavDropdown>
        </Nav>
    );
};

export default LoggedInDropDown;