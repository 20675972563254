import { useSelector } from "react-redux";
import { LoginRegister, LoggedInDropDown } from "./NavLoginComponents";


const NavLoginRegister = () => {
    const { token } = useSelector(
      (state) => state.authentication,
    );
    return <>{token ? <LoggedInDropDown /> : <LoginRegister />}</>
    ;
};

export default NavLoginRegister;