import { Fragment } from "react";
import { Home, Login, Register, Validate, Error, Dashboard, Profile, Spinner, AdminHome, RallyHandler, Registered } from "./components";
import {  
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { useSelector } from "react-redux";



import "bootstrap/dist/css/bootstrap.min.css";



const App = () => {
  const { token, loginSuccess, redirectUrl, roles } = useSelector(
    (state) => state.authentication,
  );
  //const { lastTokenCheck } = useSelector((state) => state.account);


  const bigRedirect = (loginSuccess && redirectUrl);

  const isAdmin = roles.includes("admin");

  let rallyChildren = [
    {
      path: "/rallies/",
      element: <RallyHandler />
    },
    {
      path: "/rallies/:rallySlug",
      element: <RallyHandler />,
    },
    {
      path: "/rallies/:rallySlug/registered",
      element: <Registered />,
    }
  ];

  let homeChildren = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "validate/:token?", // the parenthesis denote the param is optional
      element: <Validate />,
    },
    {
      path: "login",
      element: (
        bigRedirect ? <Navigate to={redirectUrl} /> : loginSuccess ? <Navigate to="/" /> : token ? <Navigate to="/" /> : <Login />
      )
    },
    {
      path: "register",
      element: (
        (token || localStorage.getItem('token')) ? <Navigate to="/" /> : <Register />
      )
    },
    {
      path: "rallies",
      children: rallyChildren,
    },
    {
      path: "profile",
      element: (
        localStorage.getItem('token') ? token ?  <Profile /> : <Spinner /> : <Navigate to="/" />
      ),
    },
    {
      path: "admin",
      element: (
        localStorage.getItem('token')? isAdmin? <AdminHome /> : <Spinner /> : <Navigate to="/" /> 
      )
    }
  ];

  // the last child route added is the error route
  homeChildren.push({
    path: "*",
    element: <Error />,
  });
  let browserList = [
    {
      path: "/",
      element: <Dashboard />,
      children: homeChildren,
    },
  ];
  const router = createBrowserRouter(browserList);
  
  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  );
};

export default App;
