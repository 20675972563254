import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    open: false
};

const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload;
        },
        toggleOpen: (state) => {
            state.open = !state.open;
        }
    }
});

export const navigationSliceActions = navigationSlice.actions;
export default navigationSlice.reducer;